import React, { useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { TokenProvider } from './components/TokenContext.js';
import { WalletProvider } from './components/WalletContext';
import { RelayProvider } from './components/RelayContext.js';
import { SignProvider } from './components/SignContext.js';
import Header from './components/Header/Header.js';
import MainPage from './components/mainPage.js';
//import { trackConnectWebsite } from './components/EventTracker';
import trackEvent from './components/EventTracker';
import { isMobile, browserName } from 'react-device-detect';

function App() {

  useEffect(() => {
    //console.log('Tracking connect_website event');
    // const ip_address = 'ip_address';
    // const device_info = 'device_info';
    // trackConnectWebsite(ip_address, device_info);
    fetch('https://api.ipify.org?format=json')
      .then(response => response.json())
      .then(data => {
        const ip_address = data.ip;
        const device_info = `Device Type: ${isMobile ? 'Mobile' : 'Desktop'}, Browser: ${browserName}`;
        const connectInfo = `${ip_address}, ${device_info}`
        trackEvent('connect_website', connectInfo);
      })
      .catch(error => console.error('Error fetching IP address:', error));
  }, []);

  return (
    <body>

      <WalletProvider>
        <TokenProvider>
          <RelayProvider>
            <SignProvider>
              <Header />
              <Routes>
                <Route path="/" element={<MainPage />} />
              </Routes>
            </SignProvider >
          </RelayProvider>
        </TokenProvider>
      </WalletProvider>

    </body>
  );
}

export default App;