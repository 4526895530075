import React, { useEffect, useState } from 'react';
import AirdropLogin from './AirdropLogin';
import { FinalPage } from './Final';
import { useWallet } from './WalletContext';
import { useTokenContext } from './TokenContext';

const MainPage = () => {
    const { walletInfo } = useWallet();
    const [isEligible, setIsEligible] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const { setEligibilityChecked, eligibilityChecked } = useTokenContext();

    useEffect(() => {
        if (!walletInfo.isConnected) {
            setIsEligible(false);
            setEligibilityChecked(false);
        } else if (!isLoading && !isEligible && walletInfo.address && walletInfo.isConnected && eligibilityChecked) {
            // console.log('isLoading:', isLoading);
            // console.log('isEligible:', isEligible);
            // console.log('walletInfo.address:', walletInfo.address);
            // console.log('walletInfo.isConnected:', walletInfo.isConnected);
            // console.log('eligibilityChecked:', eligibilityChecked);
            alert('Your address does not meet the airdrop requirements.');
        }
    }, [walletInfo.isConnected, isLoading, isEligible, eligibilityChecked]);


    return (
        <div>
            {isEligible ? (
                <FinalPage />
            ) : (
                <AirdropLogin setIsEligible={setIsEligible} isLoading={isLoading} setIsLoading={setIsLoading} />
            )}
        </div>
    )
};

export default MainPage;
