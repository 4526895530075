import React, { createContext, useContext, useState, useEffect } from 'react';

const WalletContext = createContext();

export const useWallet = () => useContext(WalletContext);

export const WalletProvider = ({ children }) => {
    const [walletInfo, setWalletInfo] = useState({
        address: null,
        provider: null,
        isConnected: false,
    });

    useEffect(() => {
        if (walletInfo && walletInfo.address != null) {
            //console.log('WalletInfo Updated:', walletInfo);
        }

    }, [walletInfo]);

    return (
        <WalletContext.Provider value={{ walletInfo, setWalletInfo }}>
            {children}
        </WalletContext.Provider>
    );
};

export default WalletContext;