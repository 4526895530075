"use client";
import React from 'react';
import { Link } from 'react-router-dom';
import MobileMenu from '../MobileMenu/MobileMenu';
import logoImage from '../../image/mode_logo.png';
import ConnectButton from '../ConnectButton.js';


const Header = () => {
    const menuItems = [
        { text: "Airdrop" },
        { text: "About" },
        { text: "Developers" },
        { text: "Bridge" },
    ];
    return (
        <header className="sticky top-0 z-50 border-neutral-600 border-b-[1px] px-4 md:px-20 h-[80px] flex items-center justify-between bg-custom-black">
            <MobileMenu className="flex md:hidden" />
            <Link target="_blank" href="https://mode.network/">
                <img src={logoImage} alt="logo" width={130} height={40} />
            </Link>
            <div className="space-x-4 hidden md:flex">
                {menuItems.map(({ text }) => {
                    return (
                        <Link
                            key={text}
                            to="#"
                            className={`text-neutral-100 font-medium text-primary-500`}
                        >
                            {text}
                        </Link>
                    );


                })}
            </div>
            <div className="flex items-center gap-4">
                <ConnectButton />
            </div>
        </header>
    );
};

export default Header;
