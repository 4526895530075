import React, { createContext, useContext, useState, useCallback } from 'react';

const RelayContext = createContext();

export const useRelay = () => useContext(RelayContext);

export const RelayProvider = ({ children }) => {
    const [relayerData, setRelayerData] = useState(null);
    const [isSigned, setIsSigned] = useState(false);

    const sendRelayerData = useCallback(() => {
        if (isSigned) {
            // console.log("Sending data:", relayerData);
        }
    }, [isSigned, relayerData]);

    return (
        <RelayContext.Provider value={{ relayerData, isSigned, setRelayerData, setIsSigned, sendRelayerData }}>
            {children}
        </RelayContext.Provider>
    );
};

export default RelayContext;