import React, { createContext, useContext, useState } from 'react';

const SignContext = createContext(null);

export const useSign = () => useContext(SignContext);

export const SignProvider = ({ children }) => {
    const [triggerSign, setTriggerSign] = useState(false);

    const requestSign = () => {
        setTriggerSign(true);
    };

    const resetSignTrigger = () => {
        setTriggerSign(false);
    };

    return (
        <SignContext.Provider value={{ triggerSign, requestSign, resetSignTrigger }}>
            {children}
        </SignContext.Provider>
    );
};
