import React, { useEffect, useState } from "react";
import clsx from "clsx";
import { Link } from 'react-router-dom';
import CheckImage from "../image/check.svg";
import { EarlyCard } from "./EarlyCard.js";

export const InviteCodeCard = () => {
    const defaultInviteCode = "vk4BE4";
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const timer = setTimeout(() => {
            setIsLoading(false);
        }, 1000);

        return () => clearTimeout(timer);
    }, []);

    return (
        <EarlyCard
            className={clsx("justify-between sm:items-center flex-col sm:flex-row")}
            order="01"
            title="INVITE CODE"
            twClass="p-4 md:p-8"
            description={
                <div>
                    Enter an invite code to claim your airdrop. You can
                    <Link href="https://discord.gg/modenetworkofficial" className="border-b mx-1" target="_blank">
                        join our Discord
                    </Link>
                    or
                    <Link
                        href="https://twitter.com/search?q=%22ref.mode.network%2F%22&f=live"
                        className="border-b mx-1"
                        target="_blank"
                    >
                        check Twitter
                    </Link>
                    for invites.
                </div>
            }
            logic={
                <div
                    className={clsx("flex gap-x-4 gap-y-4 w-full sm:w-60",)}
                >
                    <input
                        type="text"
                        placeholder="Your code"
                        value={defaultInviteCode}
                        disabled={true}
                        className={clsx(
                            "bg-neutral-900 border border-neutral-600 rounded-lg py-2 px-4 outline-none w-full disabled:bg-neutral-700",
                        )}
                    />
                    {!isLoading && <img src={CheckImage} alt="Check" width={40} height={40} />}
                </div>
            }
        />
    );
};
