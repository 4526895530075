import React, { useEffect } from 'react';
import {
    createWeb3Modal,
    defaultConfig,
    useWeb3ModalProvider,
    useWeb3ModalAccount,
    useWeb3ModalTheme
} from '@web3modal/ethers5/react';
import { useWallet } from './WalletContext.js';
import { ethers } from 'ethers'

const mainnet = {
    chainId: 1,
    name: 'Ethereum',
    currency: 'ETH',
    explorerUrl: 'https://etherscan.io',
    rpcUrl: 'https://cloudflare-eth.com'
}

const metadata = {
    name: 'My Website',
    description: 'My Website description',
    url: 'ws://localhost.com:3000',
    icons: ['https://avatars.mywebsite.com/']
}

createWeb3Modal({
    projectId: process.env.REACT_APP_PROJECT_ID,
    chains: [mainnet],
    ethersConfig: defaultConfig({ metadata }),
});

function WalletConnectButton() {
    const { setWalletInfo } = useWallet();
    const { address, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    const { setThemeMode, setThemeVariables } = useWeb3ModalTheme()

    setThemeMode('dark')
    setThemeVariables({
        '--w3m-accent': '#DFFE00',
        '--w3m-font-family': '#030303',
        '--w3m-border-radius-master': '1px'
    })

    useEffect(() => {
        if (walletProvider) {
            const provider = new ethers.providers.Web3Provider(walletProvider);
            setWalletInfo({
                address: isConnected ? address : null,
                isConnected,
                provider
            });

        } else {
            setWalletInfo({
                address: null,
                isConnected: false,
                provider: null
            });
        }
    }, [address, isConnected, walletProvider]);

    return (
        <w3m-button />
    );
}

export default WalletConnectButton;
