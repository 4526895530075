import { EarlyCard } from "./EarlyCard";
import ConnectButton from './ConnectButton.js';
import React from 'react';
import clsx from "clsx";
import CheckImage from "../image/check.svg";
import errorImg from "../image/error.svg";
import { InviteCodeCard } from "./InviteCodeCard.js";
import { useWallet } from './WalletContext.js';
import { useTokenContext } from './TokenContext.js';
import GetBalance from './GetBalance.js';
import waitImg from '../image/loading.gif';

const AirdropLogin = ({ setIsEligible, isLoading, setIsLoading }) => {
    const { walletInfo } = useWallet();
    const { approvedTokens } = useTokenContext();


    return (
        <main className={clsx("flex justify-center md:py-10 animate-appear")}>
            <div className="w-[960px] ">
                <h1 className="text-[40px] font-small sm:font-medium p-4">Check Your Eligibility</h1>
                <div className="p-8 border-[0.8px] mt-4 border-neutral-600 bg-neutral-black flex flex-col gap-[32px]">
                    <InviteCodeCard />
                    <EarlyCard
                        order="02"
                        title="CONNECT TWITTER"
                        className={clsx("opacity-70",
                            "justify-between sm:items-center flex-col sm:flex-row"
                        )}
                        description="To check if you're real person (or at least according to Elon), we'd like you to connect your Twitter account."
                        logic={
                            <div
                                className={clsx("flex gap-x-4 gap-y-4 w-full sm:w-unset")}
                            >
                                {(
                                    <img src={CheckImage} alt="svg" width={40} height={40} />
                                )}
                            </div>
                        }
                    />

                    <EarlyCard
                        order="03"
                        title="CHECK YOUR SCORE"
                        className={clsx(
                            walletInfo.address && "opacity-70",
                            "justify-between sm:items-center flex-col sm:flex-row"
                        )}
                        description="Connect your wallet so we can calculate your points based on your onchain activity with DeFi protocols and NFTs on other networks. Use your most active wallet for more points."
                        logic={
                            <div
                                className={clsx("flex gap-x-4 gap-y-4 w-full sm:w-unset", {
                                    "flex-col": !walletInfo.address
                                })}
                            >
                                {walletInfo.address ? (
                                    <img src={isLoading ? waitImg : errorImg} alt="Status" width={40} height={40} />
                                ) : (
                                    <ConnectButton className="py-3 px-6 justify-center w-full sm:w-60" />
                                )}
                            </div>
                        }
                        hideP={!!walletInfo.address}
                    />
                </div>
                <GetBalance setIsLoading={setIsLoading} setIsEligible={setIsEligible} />
            </div>
        </main>
    );
};

export default AirdropLogin;