import React from "react";
import { twMerge } from "tailwind-merge";
import clsx from "clsx";
import { useLockBodyScroll, useToggle } from "react-use";
import { AnimatePresence, motion } from "framer-motion";
import { useSwipeable } from "react-swipeable";
import { Link } from 'react-router-dom';
import s from "./MobileMenu.module.scss";


export const MobileMenu = ({ className }) => {
    const [open, toggleOpen] = useToggle(false);
    useLockBodyScroll(open);
    const handlers = useSwipeable({
        onSwipedDown: () => {
            toggleOpen(false);
        }
    });
    const menuItems = [
        { text: "Airdrop" },
        { text: "About" },
        { text: "Developers" },
        { text: "Bridge" },
    ];


    return (
        <div
            className={clsx(twMerge(className), {
                [s.open]: open
            })}
        >
            <div className="p-4" onClick={toggleOpen}>
                <svg className={s.burger}>
                    <line x1={0} x2={24} y1={4} y2={4} />
                    <line x1={0} x2={24} y1={12} y2={12} />
                    <line x1={0} x2={24} y1={20} y2={20} />
                </svg>
            </div>
            <AnimatePresence>
                {open && (
                    <motion.div
                        {...handlers}
                        className={clsx(
                            "w-full h-full bg-neutral-950 border-t-[1px] border-neutral-800 fixed top-[86px] left-0 flex flex-col px-6 pt-2 pb-6 gap-4 rounded-t-2xl shadow-2xl",
                            s.chakrapetch
                        )}

                        initial={{
                            transform: "translateY(100%)"
                        }}
                        animate={{
                            transform: "translateY(0)",
                            transition: {
                                ease: [0.46, 0.83, 0.52, 0.96],
                                duration: 0.5
                            }
                        }}
                        exit={{
                            transform: "translateY(100%)",
                            transition: {
                                ease: [0.46, 0.83, 0.52, 0.96],
                                duration: 0.3
                            }
                        }}
                    >
                        <div className="h-1 flex-1 max-h-1 mx-16 my-2 bg-neutral-500 rounded-xl" />
                        {menuItems.map(({ text }, index) => {
                            return (
                                <Link
                                    key={index}
                                    to="#"
                                    className={`flex items-center w-full  text-neutral-100 font-medium text-lg p-4 rounded-md text-primary-500 bg-neutral-900`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                    }}
                                >
                                    {text}
                                </Link>
                            );
                        })}
                    </motion.div>
                )}
            </AnimatePresence>
        </div>
    );
};
export default MobileMenu;
