import React, { FC } from "react";
import error from '../../image/error.svg';
import check from '../../image/check.svg';

export const ActivityCard = ({ title, description, isActive }) => {
    return (
        <div className="bg-neutral-950 p-4 flex flex-col gap-y-2 rounded w-full">
            <div className="flex items-center gap-2 text-lg color-neutral-100 font-medium">
                {title}
            </div>
            <div className="text-base text-neutral-500 truncate">{description}</div>
            {(
                <img
                    src={isActive ? check : error}
                    alt="svg"
                    width={20}
                    height={20}
                />
            )}
        </div>
    );
};
