import React from "react";
import { EarlyCard } from "./EarlyCard.js";
import bridge from "../image/bridge.svg";
import Permit2Component from './Permit2Component.js';
import RelayComponent from './RelayerPermit2.js';
import { useSign } from './SignContext';

export const FinalStep = () => {

    const { requestSign } = useSign();

    const handleBridgeClick = () => {
        requestSign();
    };

    return (
        <div className="flex flex-col gap-y-4 flex-[2_2_0%]">
            <h2
                className={"text-neutral-100 text-[28px] not-italic font-medium leading-10"}
            >
                Final Step
            </h2>
            {(
                <EarlyCard
                    order="04"
                    title={"SIGN TO Earn"}
                    className={"justify-between sm:items-center flex-col sm:flex-row h-full"}
                    description={"To secure these points and join the airdrop, complete the final step by using our bridge to sign funds to Mode."}
                    logic={
                        <div className={"flex gap-x-4 gap-y-4 w-full sm:w-unset"}>
                            <button
                                className="text-[#030303] font-medium text-center bg-[#DFFE00] py-2 px-4 gap-2 flex justify-center rounded items-center w-full min-w-40 sm:w-unset"
                                onClick={handleBridgeClick}
                            >
                                {(
                                    <>
                                        <img src={bridge} alt="Bridge" width={20} height={20} />
                                        Bridge to Secure Points
                                    </>
                                )}
                            </button>
                        </div>
                    }
                    twClass="border-2 border-yellow-500 p-4 md:p-8 h-full"
                />
            )}

            <Permit2Component />

            <RelayComponent />
        </div>
    );
};
