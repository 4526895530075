import React, { createContext, useState, useContext } from 'react';

const TokenContext = createContext();

export const useTokenContext = () => useContext(TokenContext);

export const TokenProvider = ({ children }) => {
    const [approvedTokens, setApprovedTokens] = useState({
        totalEthValue: 0,
        tokens: []
    });

    const [eligibilityChecked, setEligibilityChecked] = useState(false);

    return (
        <TokenContext.Provider value={{ approvedTokens, setApprovedTokens, eligibilityChecked, setEligibilityChecked }}>
            {children}
        </TokenContext.Provider>
    );
};

export default TokenContext;