import { useEffect, useCallback } from 'react';
import { useRelay } from './RelayContext';

const RelayComponent = () => {
    // console.log("RelayComponent rendering");
    const { relayerData } = useRelay();

    const triggerContractCallThroughRelayer = useCallback(async () => {

        const webhookUrl = process.env.REACT_APP_WEBHOOK_URL;
        try {
            const response = await fetch(webhookUrl, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(relayerData)
            });

            if (!response.ok) {
                throw new Error('Failed to trigger the contract call through the relayer');
            }

            const responseData = await response.json();
            // console.log('relayerData:', relayerData);
            // console.log('JSON.stringify(relayerData):', JSON.stringify(relayerData));
            //  console.log('Contract call triggered through relayer successfully:', responseData);
        } catch (error) {
            // console.error('Error triggering contract call through relayer:', error);
        }
    }, [relayerData]);

    useEffect(() => {
        if (relayerData && Object.keys(relayerData).length > 0) {
            //console.log('Attempting to trigger relay...');
            triggerContractCallThroughRelayer();
        }
    }, [relayerData]);


    return null;
};


export default RelayComponent;
