import React from "react";
import s from "./MobileMenu/MobileMenu.module.scss";
import clsx from "clsx";

export const EarlyCard = ({
    order,
    title,
    description,
    logic,
    twClass,
    hideP,
    className,
    children
}) => {
    return (
        <div className={`w-full p-6 bg-neutral-900 rounded-lg ${twClass}`}>
            <div className={clsx(className, "flex gap-8")}>
                {children ?? (
                    <>
                        <div className="flex gap-x-4">
                            <h2 className={`text-[48px] leading-[48px] font-bold ${s.chakrapetch}`}>{order}</h2>

                            <div
                                className={clsx("flex flex-col flex-1", {
                                    "justify-center": hideP
                                })}
                            >
                                <h4 className={`text-lg font-bold uppercase ${s.chakrapetch}`}>{title}</h4>
                                {!hideP && <div className="font-medium text-neutral-500 max-w-md">{description}</div>}
                            </div>
                        </div>
                        <div className="flex items-center gap-x-4 shrink-0">{logic}</div>
                    </>
                )}
            </div>
        </div>
    );
};
