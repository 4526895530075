import React from "react";
import { PrecheckGallery } from "./PrecheckGallery/PrecheckGallery.js";
import { FinalStep } from './FinalStep.js';

export const FinalPage = () => {

    return (
        <main className="flex justify-center md:py-10 animate-appear">
            <div className="w-[987px] ">
                <div className="p-8 mt-4 border-[0.8px] border-neutral-600 bg-neutral-black flex flex-col gap-4">
                    <h2 className="text-neutral-100 text-[28px] not-italic font-medium leading-10">Pre-check</h2>
                    <div
                        className={"flex flex-col md:flex-col gap-y-10 gap-4"}
                    >
                        <PrecheckGallery />
                        <FinalStep />
                    </div>
                </div>
            </div>
        </main >
    );
};
