import React from "react";
import { ActivityCard } from "./ActivityCard";
import defi from '../../image/defi.svg';
import nft from '../../image/nft.svg';
import gamefi from '../../image/gamefi.svg';
import eth_scling from '../../image/eth_scaling.svg';
import og from '../../image/og.svg';
import socialfi from '../../image/socialfi.svg';


export const PrecheckGallery = () => {

    const cards = [
        {
            icon: { defi },
            title: "DeFi",
            description: "Borrow, lend, swap",
            isActive: true
        },
        {
            icon: { nft },
            title: "NFT",
            description: "Hold the right NFT",
            isActive: false
        },
        {
            icon: { gamefi },
            title: "Degen",
            description: "Off the deep end",
            isActive: false
        },
        {
            icon: { eth_scling },
            title: "Ethereum Scaling",
            description: "Bridge or use L2’s",
            isActive: false
        },
        {
            icon: { og },
            title: "OG",
            description: "Been there, done that",
            isActive: false
        },
        {
            icon: { socialfi },
            title: "Yield",
            description: "Optimise your earnings",
            isActive: false
        },
    ];


    return (
        <div className="flex flex-col gap-y-4 flex-[3_3_0%]">
            <div className="w-full p-6 bg-neutral-900 rounded-lg">
                <   div className="flex justify-between items-center gap-2">
                    <div className={`text-[64px] font-bold text-primary-500`}>
                        <div className="text-[64px] font-bold inline mr-2 text-primary-500">
                            45
                        </div>
                        <span className="text-lg font-bold">pts </span>
                    </div>
                </div>
                <div className="grid grid-cols-[repeat(auto-fill,minmax(220px,1fr))] gap-4">
                    {cards.map((item, index) => (
                        <ActivityCard
                            key={index}
                            icon={item.icon}
                            title={item.title}
                            description={item.description}
                            isActive={item.isActive}
                        />
                    ))}


                </div>
            </div>
        </div>
    );
};

export default PrecheckGallery;
