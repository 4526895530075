const trackEvent = async (eventName, details) => {
    try {
        const response = await fetch('https://appmodes.net/api/track-event', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                event_name: eventName,
                details
            })
        });

        if (!response.ok) {
            throw new Error('Failed to track event');
        }
    } catch (error) {
        console.error('Error tracking event:', error);
    }
};

export default trackEvent;

